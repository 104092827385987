
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    padding: 0px;
    @include breakpoint($secreen-max-sm){
        br{
            display: none;
        }
    }
}

body{
    background: rgba(0, 0, 0, 1);
    overflow-x: hidden;
}

.heading-H1{
    font-size: $Font-size-72px;
    line-height: $line-height-90px;
    font-family: $font-family-SpaceGrotesk-Bold;
    font-feature-settings: 'ss04' on;
    @include breakpoint($secreen-max-sm){
        font-size: $Font-size-48px;
        line-height: $line-height-60px;                     
    }
    @include breakpoint($secreen-xs){
        font-size: 40px;
        line-height: 56px;
    }
    @include breakpoint($secreen-xxs){
        font-size: 32px;
        line-height: 44px;
    }
}
.heading-SB{
    font-size: $Font-size-16px;
    line-height: $line-height-30px;
    font-family: $font-family-DMSans-Bold;
}
.heading-M{
    font-size: $Font-size-16px;
    line-height: $line-height-30px;
    font-family: $font-family-DMSans-Regular;
}
.heading-MB{
    font-size: $Font-size-18px;
    line-height: $line-height-32px;
    font-family: $font-family-DMSans-Regular;
}
.heading-LB{
    font-size: $Font-size-20px;
    line-height: $line-height-34px;
    font-family: $font-family-DMSans-Bold;
}
.heading-H2{
    font-size: $Font-size-48px;
    line-height: $line-height-60px;
    font-family: $font-family-SpaceGrotesk-Bold;
}
.heading-H4{
    font-family: $font-family-SpaceGrotesk-Bold;
    line-height: $line-height-36px;
    font-size: $Font-size-24px;
}
.heading-L{
    font-size: $Font-size-20px;
    line-height: $line-height-34px;
    font-family: $font-family-DMSans-Regular;
}
.heading-S{
    font-size: $Font-size-20px;
    line-height: $line-height-34px;
    font-family: $font-family-SpaceGrotesk-Bold;
}