/*------------------------------------------
        Font-Size Start Here
-------------------------------------------*/

$Font-size-20px: 20px;
$Font-size-18px: 18px;
$Font-size-16px: 16px;
$Font-size-72px: 72px;
$Font-size-48px: 48px;
$Font-size-24px: 24px;


/*------------------------------------------
        Line-Height Start Here
-------------------------------------------*/

$line-height-30px: 30px;
$line-height-32px: 32px;
$line-height-34px: 34px;
$line-height-90px: 90px;
$line-height-60px: 60px;
$line-height-36px: 36px;



/*=====================================
        Color Variable's
=====================================*/
$Gradient: linear-gradient(90deg, #EF2D56 0%, #662A96 100%);;
$White: #FFFFFF;
$Black: #000;
$White-light:rgba(255, 255, 255, 0.1);
$White-65: rgba(255, 255, 255, 0.65);
$White-5:rgba(255, 255, 255, 0.05);
$White-75: rgba(255, 255, 255, 0.75);



/*=====================================
        Font-family Variable's
=====================================*/

$font-family-DMSans-Regular: "DMSans-Regular";
$font-family-DMSans-Bold: "DMSans-Bold";
$font-family-SpaceGrotesk-Bold: "SpaceGrotesk-Bold";
