
.loader-main{
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: $Black;
	overflow: hidden;
	transition: ease 0.2s;
	z-index: 99999;
    p {font-family: 'Montserrat Alternates', sans-serif;font-size:18px; font-weight:700;padding-bottom:20px;color:#FFF;}
    
    .loader-main.hidden {
        animation: fadeOutt 1s;
        animation-fill-mode: forwards;
        overflow-y: visible;
    }
    
    @keyframes fadeOutt {
        100% {
            visibility: hidden;
            opacity: 0;
        }
    }

    .loader {
       width:50px;
       height:50px;
       display:inline-block;
       padding:0px;
       opacity:0.5;
       border:3px solid #09acfd;
       -webkit-animation: loader 1s ease-in-out infinite alternate;
       animation: loader 1s ease-in-out infinite alternate;
    }
    
    .loader:before {
      content: " ";
      position: absolute;
      z-index: -1;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border: 3px solid #09acfd;
    }
    
    .loader:after {
      content: " ";
      position: absolute;
      z-index: -1;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border: 3px solid #09acfd;
    }

    .row-custom{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
    }
    
    @keyframes loader {
       from {transform: rotate(0deg) scale(1,1);border-radius:0px;}
       to {transform: rotate(360deg) scale(0, 0);border-radius:50px;}
    }
    @-webkit-keyframes loader {
       from {-webkit-transform: rotate(0deg) scale(1, 1);border-radius:0px;}
       to {-webkit-transform: rotate(360deg) scale(0,0 );border-radius:50px;}
    }
}


header .main .container .top-header-fixed .nave-bar .ul-items .li-items a.active{
    color: $White !important;
}

header .main .container .top-header-fixed .nave-bar .ul-items .li-items a.active::after{
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    transform: translate(-50% , -50%);
    background-image: url("/assets/img/box.png");
    top: 46%;
    left: -15px;
    visibility: visible;
    opacity: 1;
    transition: .4s;
}    

.home-active{
    color: $White !important;
}

.home-active::before{
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    transform: translate(-50% , -50%);
    background-image: url("/assets/img/box.png");
    top: 46%;
    left: -15px;
    visibility: visible;
    opacity: 1;
    transition: .4s;
}  

.home::before{
    display: none;
}

/*------------------------------------------
            Hero Start Here
-------------------------------------------*/

header{
    overflow: hidden;
    margin-bottom: 130px;
    position: relative;
    z-index: 100;
    .main{
        position: relative;
        &::after{
            content: "";
            position: absolute;
            width: 500px;
            height: 500px;
            background: $Gradient;
            border-radius: 100%;
            top: 50%;
            transform: translateY(-50%);
            filter: blur(250px);
            right: -230px;
            z-index: 15;
        }
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $Black;
            opacity: .65;
            z-index: 2;
        }
        .container{
            position: relative;
            height: auto;
            z-index: 20;
            @include breakpoint($secreen-lg){
                width: 100%;
                max-width: 100%;
            }
            .nav-otr{
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                transition: .3s;
                z-index: 200;
                .nav-bar{
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    max-width: 1320px;
                    padding: 32px 12px 0 12px;
                    margin: 0 auto;
                    .logo{
                        .logo-img{
                            .path{
                                fill: $White;
                            }
                        }
                    }
                    .nave-bar{
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }
                       .ul-items{
                           display: flex;
                           margin-bottom: 0px;
                           .li-items{
                                position: relative;
                                &:not(:last-child){
                                    padding-right: 35px;
                                    @include breakpoint($secreen-max-lg){
                                        padding-right: 20px;
                                    }
                                }
                                .a-items{
                                     color: $White-65;
                                     transition: .4s;
                                    &:hover{
                                        color: $White;
                                    }
                                }
                           }
                       }
                    }
                    .burger-icon{
                        @include breakpoint($secreen-min-lg){
                           display: none;
                        }
                        
                    }
                    
                    .offcanvas{
                        .offcanvas-header{

                        }
                        .offcanvas-body{
                            .overlay-ul{
                                .overlay-li{
                                    .overlay-a{
                                        color: $Black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .top-header-fixed{
                background-color: $Black;
                padding-bottom: 32px;
                z-index: 200;
                .nave-bar{
                    .ul-items{
                        .li-items{
                            .a-items{
                                color: #fff !important;
                            }
                        }
                    }
                }
            }
            .col-otr{
                .col-innr{
                    padding-top: 210px;
                    padding-bottom: 170px;
                    position: relative;
                    z-index: 10;
                    @include breakpoint($secreen-xs){
                        padding-top: 150px;
                        padding-bottom: 100px;
                        padding-right: 0;
                    }
                    .heading-H1{
                        color: $White;
                    }
                    .heading-L{
                        padding-top: 40px;
                        padding-bottom: 48px;
                        color: $White-65;
                        @include breakpoint($secreen-xs){
                            padding: 20px 0 20px 0;
                        }
                    }
                    .action{
                        display: flex;
                        position: relative;
                        .theme-btn{
                            display: flex;
                            padding: 13px 30px;
                            align-items: center;
                            position: relative;
                            overflow: hidden;
                            color: $White;
                            z-index: 1;
                            &::after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 256%;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%) rotate(45deg);
                                background: $Gradient;
                                transition: .3s;
                                z-index: -1;
                            
                            }
                            .icon-innr{
                                font-size: 24px;
                                margin-left: 14px;
                            }
                        }
                    
                    } 
                }
            }
        }
        .video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

/*------------------------------------------
            Hero End Here
-------------------------------------------*/

/*------------------------------------------
            About Start Here
-------------------------------------------*/
.about{
    padding: 130px 0;
    position: relative;
    overflow: hidden;
    @include breakpoint($secreen-xs){
        padding: 80px 0 80px 0;
    }
    .container{
        .row-custom{
            justify-content: end;
            .col-content{
                .col-innr{
                    position: relative;
                    z-index: 10;
                    .heading-LB{
                        background-image: $Gradient;
                        color: transparent;
                        display: inline;
                        -webkit-background-clip: text;
                    }
                    .heading-H2{
                        padding-top: 16px;
                        padding-bottom: 24px;
                        align-items: center;
                        color: $White;
                    }
                    .heading-M{
                        color: $White-65;
                    }
                    .heading-H4{
                        color: $White;
                        padding-top: 32px;
                        padding-bottom: 14px;
                    }
                    .linkk{
                        background-image: $Gradient;
                        color: transparent;
                        display: inline;
                        -webkit-background-clip: text;
                    }
                }
            }
        }
    }
    .video{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 45%;
        @include breakpoint($secreen-max-md){
            position: relative;
            left: 0;
            top: 0;
            transform: translateY(0);
            width: 100%;
            height: auto;
            margin-top: 55px;
        }
    }
}

/*------------------------------------------
            About End Here
-------------------------------------------*/
/*------------------------------------------
            Statistics Start Here
-------------------------------------------*/

  .statistics{
      margin: 130px 0 0 0;
      @include breakpoint($secreen-max-md){
          margin: 0;
      }
      .row-custom{
        padding: 56px 72px;
        position: relative;
        overflow: hidden;
        @include breakpoint($secreen-xs){
            background: $Gradient;
            margin: 0 6px;
        }
        &::after{
            content: "";
            width: 109%;
            height: 401%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(35deg);
            background: $Gradient;
            position: absolute;
            z-index: -1;
            @include breakpoint($secreen-xs){
                display: none;
            }
        }
        
          .col-box{
            &:not(:last-child){
                @include breakpoint($secreen-xs){
                    margin-bottom: 20px;
                }
            }
              .col-innr{
                display: flex;
                justify-content: center;
                flex-direction: column;
                @include breakpoint($secreen-xs){
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                }
                  .icon-content{
                      display: flex;
                      align-items: center;
                      padding-bottom: 8px;
                      @include breakpoint($secreen-xs){
                        justify-content: center;
                    }
                      .icon-otr{
                          margin-right: 18px;
                        .icon-innr{
                          font-size: 48px;
                          color: $White;
                        }
                      }
                      .heading-H2{
                        color: $White;
                      }
                     
                  }
                  .heading-M{
                      color: $White-65;
                      padding-bottom: 0px;
                  }
              }
          }
      }
  }

/*------------------------------------------
            Statistics End Here
-------------------------------------------*/

/*====================================
        R-Work Start Here
====================================*/

.recent-work{
    padding-top: 130px;
    @include breakpoint($secreen-xs){
        padding-top: 80px;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 40px;
            .heading-LB{
             display: inline;
             background-image: $Gradient;
             color: transparent;
              -webkit-background-clip: text;
              
            }
            .heading-H2{
                padding-top: 16px;
                page-break-inside: 37px;
                color: $White;
            }
        }
        .row-custom{
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-gap: 72px 135px;
            .logo-otr{
                .logo-inr{
                    position: relative;
                    transition: .3s;
                    @include breakpoint($secreen-xxs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &:hover{
                        .box-hover{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    .logo{
                        width: 100%;
                        @include breakpoint($secreen-xxs){
                            width: 200px;
                        }
                    }
                    .box-hover{
                        position: absolute;
                        overflow: hidden;
                        width: 350px;
                        padding: 24px 32px;
                        bottom: 50px;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 999;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s;
                        &::after{
                            content: "";
                            width: 94%;
                            position: absolute;
                            height: 348%;
                            background-color: #191919;
                            opacity: 1;
                            top: 50%;
                            z-index: 1;
                            left: 50%;
                            transform: translate(-50% , -50%) rotate(40deg);
                            @include breakpoint($secreen-max-sm){
                                transform: translate(0,0) rotate(0deg);
                                height: 100%;
                                top: 0;
                                left: 0;
                            }
                        }
                        .desc-hover{
                            color: $White-65;
                            position: relative;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
}

/*====================================
        R-Work End Here
====================================*/

/*====================================
        Masternodes Start Here
====================================*/

.masternode{
    padding-top: 128px;
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 40px;
            .heading{
                color: $White;
            }
        }
        .row-custom{
            margin-bottom: 40px;
            .col-content-otr{
                &:not(:last-child){
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 16px;
                    }
                }
                .col-content-inr{
                    padding: 0 25px 0 0;
                    .desc{
                        color: $White-65;
                    }
                }
            }
        }
        .row-custom2{
            display: grid;
            align-items: center;
            justify-content: center;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-gap: 72px 135px;
            .logo-otr{
                .logo-inr{
                    @include breakpoint($secreen-xxs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .logo{
                        width: 100%;
                        @include breakpoint($secreen-xxs){
                            width: 200px;
                        }
                    }
                }
            }
        }
    }
}

/*====================================
        Masternodes End Here
====================================*/

/*------------------------------------------
            Services Start Here
-------------------------------------------*/

.services{
    padding: 130px 0;
    overflow: hidden;
    position: relative;
    @include breakpoint($secreen-xs){
        padding: 80px 0;
    }
    &::after{
        content: "";
        position: absolute;
        width: 500px;
        height: 500px;
        background: $Gradient;
        border-radius: 100%;
        filter: blur(500px);
        bottom: -266px;
        left: 513px;
    }
       .container{
           .wrapper{
               text-align: center;
               .heading-LB{
                display: inline;
                background-image: $Gradient;
                color: transparent;
                 -webkit-background-clip: text;
               }
               .heading-H2{
                   padding-top: 16px;
                   page-break-inside: 37px;
                   color: $White;
               }
           }
         .row-custom{
             justify-content: center;
             .col-box{
                 margin-top: 24px;
                .col-innr{
                    padding: 36px 36px 24px 36px;
                    position: relative;
                    // height: 300px;
                    opacity: 1;         
                    overflow: hidden;
                    .icon-content{
                        padding: 16px 26px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        overflow: hidden;
                        &::after{
                            content: "";
                            width: 120%;
                            position: absolute;
                            height: 348%;
                            background-color: $White;
                            opacity: 0.05;
                            top: 50%;
                            z-index: 3;
                            left: 50%;
                            transform: translate(-50% , -50%) rotate(40deg);
                            @include breakpoint($secreen-max-sm){
                                transform: translate(0,0) rotate(0deg);
                                height: 100%;
                                top: 0;
                                left: 0;
                            }
                        }
                        .icon-otr{
                            margin-right: 16px;
                            .icon-innr{
                                  font-size: 32px;
                                  color: $Gradient;
                            }
                        }
                        .heading-H4{
                            color: $White; 
                            margin-bottom: 0;
                        }
                    }
                    .try{
                        &::after{
                            content: "";
                            width: 120%;
                            position: absolute;
                            height: 368%;
                            background-color: #FFFFFF;
                            opacity: 0.05;
                            top: 50%;
                            z-index: 3;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(137deg);
                            @include breakpoint($secreen-max-sm){
                                transform: none;
                                height: 100%;
                                top: 0;
                                left: 0;
                            }
                        }
                       
                    }
                    .heading-M{
                        color: $White;
                        opacity: 0.75;
                        margin-bottom: 0px;
                        padding-top: 24px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        background-color: #FFFFFF;
                        opacity: 0.05;
                        height: 300%;
                        transform: translate(-50%, -50%) rotate(-46deg);
                        top: 50%;
                        left: 50%;
                        z-index: 2;
                        @include breakpoint($secreen-xs){
                            transform: translate(0,0) rotate(0deg);
                            height: 100%;
                            top: 0;
                            left: 0;
                        }
                    }
                }
                .box-1{
                    &::after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        background-color: #FFFFFF;
                        opacity: 0.05;
                        height: 300%;
                        transform: translate(-50%, -50%) rotate(46deg);
                        top: 50%;
                        left: 50%;
                        z-index: 2;
                        @include breakpoint($secreen-xs){
                            transform: translate(0,0) rotate(0deg);
                            height: 100%;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
            Services End Here
-------------------------------------------*/

/*====================================
        Partnership Start Here
====================================*/

.Partnership-logo{
    padding-top: 130px;
    @include breakpoint($secreen-xs){
        padding-top: 80px;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 56px;
            .heading-LB{
             display: inline;
             background-image: $Gradient;
             color: transparent;
              -webkit-background-clip: text;
              
            }
            .heading-H2{
                padding-top: 16px;
                page-break-inside: 37px;
                color: $White;
            }
        }
        .row-custom{
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-gap: 72px 30px;
            @include breakpoint($secreen-max-sm){
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
            .logo-otr{
                .logo-inr{
                    @include breakpoint($secreen-xxs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .logo{
                        width: 100%;
                        @include breakpoint($secreen-xxs){
                            width: 200px;
                        }
                    }
                }
            }
        }
    }
}

/*====================================
        Partnership End Here
====================================*/
/*====================================
        Connections Start Here
====================================*/

.Connections-logo{
    padding: 81px 0 144px 0;
    @include breakpoint($secreen-xs){
        padding-top: 80px;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 56px;
            .heading-LB{
             display: inline;
             background-image: $Gradient;
             color: transparent;
              -webkit-background-clip: text;
              
            }
            .heading-H2{
                padding-top: 16px;
                page-break-inside: 37px;
                color: $White;
            }
        }
        .row-custom{
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-gap: 72px 30px;
            @include breakpoint($secreen-max-sm){
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
            .logo-otr{
                .logo-inr{
                    @include breakpoint($secreen-xxs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .logo{
                        width: 100%;
                        @include breakpoint($secreen-xxs){
                            width: 200px;
                        }
                    }
                }
            }
        }
    }
}

/*====================================
        Connections End Here
====================================*/


/*====================================
        community start here
====================================*/

    .community-section{
        background-color: $White-5;
        padding: 130px 0;
        position: relative;
        overflow: hidden;
        &::after{
            @include breakpoint($secreen-max-md){
                display: none;
            }
            content: "";
            position: absolute;
            width: 500px;
            height: 500px;
            background: $Gradient;
            border-radius: 100%;
            filter: blur(300px);
            top: 49px;
            right: -250px;
            z-index: -1;
        }
        @include breakpoint($secreen-max-md){
            padding: 72px 0;
        }
        .content-otr{
            
                .content-inr{
                    text-align: center;
                    
                    .section-name{
                        display: inline;
                        background-image: $Gradient;
                        color: transparent;
                         -webkit-background-clip: text;
                    }
                    .heading{
                        padding: 16px 0 24px 0;
                        color: $White;
                    }
                    .detail{
                        color: rgba(255, 255, 255, 1);
                        padding-bottom: 48px;
                    }
                    .action{
                        display: flex;
                        justify-content: center;
                        position: relative;
                        .theme-btn{
                            display: flex;
                            padding: 13px 30px;
                            align-items: center;
                            position: relative;
                            overflow: hidden;
                            color: $White;
                            z-index: 1;
                            &::after{
                                content: "";
                                position: absolute;
                                width: 141%;
                                height: 332%;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%) rotate(45deg);
                                background: $Gradient;
                                transition: 0.3s;
                                z-index: -1;
                            
                            }
                            .icon-innr{
                                font-size: 24px;
                                margin-left: 14px;
                            }
                        }
                    
                    } 
                    .img1-otr1{
                        position: absolute;
                        top: 132px;
                        left: 152px;
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }

                    }
                    .img2-otr{
                        position: absolute;
                        top: 132px;
                        right: 152px;
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }

                    }
                    .img3-otr{
                        position: absolute;
                        bottom: 132px;
                        left: 257px;
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }

                    }
                    .img4-otr{
                        position: absolute;
                        bottom: 132px;
                        right: 257px;
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }

                    }
                }
            
        }
    }




/*====================================
        community start here
====================================*/

/*====================================
        our-involvment Start Here
====================================*/

.our-involvment{
    padding: 128px 0 0 0;
    @include breakpoint($secreen-max-md){
        padding: 72px 0 0 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            .col-content-otr{
                .col-content-inr{
                    @include breakpoint($secreen-max-md){
                        padding-bottom: 72px;
                    }
                    @include breakpoint($secreen-xs){
                        padding-bottom: 48px;
                    }
                    .heading-LB{
                        background-image: $Gradient;
                        color: transparent;
                        display: inline;
                        -webkit-background-clip: text;
                    }
                    .heading-H2{
                        padding-top: 16px;
                        padding-bottom: 24px;
                        align-items: center;
                        color: $White;
                    }
                    .heading-M{
                        color: $White-65;
                    }
                }
            }
            .col-img-otr{
                .col-img-inr{              
                    .img{
                        width: 100%;
                    }
                }
            }
        }
    }
}

/*====================================
        our-involvment End Here
====================================*/

/*====================================
        invest-support Start Here
====================================*/

.invest-support{
    padding: 128px 0 0 0;
    @include breakpoint($secreen-max-md){
        padding: 72px 0 0 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            @include breakpoint($secreen-max-md){
                flex-direction: column-reverse;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    @include breakpoint($secreen-max-md){
                        padding-bottom: 72px;
                    }
                    @include breakpoint($secreen-xs){
                        padding-bottom: 48px;
                    }
                    .heading-LB{
                        background-image: $Gradient;
                        color: transparent;
                        display: inline;
                        -webkit-background-clip: text;
                    }
                    .heading-H2{
                        padding-top: 16px;
                        padding-bottom: 24px;
                        align-items: center;
                        color: $White;
                    }
                    .heading-M{
                        color: $White-65;
                    }
                }
            }
        }
    }
}

/*====================================
        invest-support End Here
====================================*/

/*------------------------------------------
            team-Section Start Here
-------------------------------------------*/

.team-section{
    position: relative;
    overflow: hidden;
    padding-top: 130px;
    .container{
        .wrapper{
            margin-bottom: 72px;
            text-align: center;
            .heading-LB{
                display: inline;
                color: transparent;
                background-image: $Gradient;
            -webkit-background-clip: text;

            }
            .heading-H2{
                color: $White;
            }
        }
        .team-swiper{
            @include breakpoint($secreen-xs){
                padding-bottom: 48px;
            }
            .swiper-wrapper{
                height: auto;
                .col-box{
                    margin-bottom: 24px;
                    @include breakpoint($secreen-xs){
                        width: 50%;
                    }
                    @include breakpoint($secreen-xxs){
                        width: 100%;
                    }
                    .col-innr{
                        padding: 24px;
                        overflow: hidden;
                        position: relative;
                        z-index: 1;
                        &:hover{
                            .content:hover::after{
                                background: $Gradient;
                            }
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(46deg);
                            width: 157%;
                            height: 119%;
                            z-index: -1;
                            background: rgba(255, 255, 255, 0.1);
                            @include breakpoint($secreen-xs){
                                transform: none;
                                top: 0;
                                left: 0;
                                height: 100%;
                            }
                        }
                        .img-otr{
                            position: relative;
                            .img-fluid{
                                width: 100%;
                            } 
                            .content{
                                position: absolute;
                                width: 100%;
                                bottom: 0;
                                left: 0;
                                padding: 16px;
                                text-align: center;
                                overflow: hidden;
                                &::after{
                                    content: "";
                                    width: 130%;
                                    height: 250px;
                                    transform: translate(-50%, -50%) rotate(45deg);
                                    position: absolute;
                                    background: rgba(255, 255, 255, 0.1);
                                    backdrop-filter: blur(25px);
                                    top: 38px;
                                    left: 131px;
                                    transition: .3s;
                                    @include breakpoint($secreen-xs){
                                        transform: none;
                                        top: 0;
                                        left: 0;
                                        height: 100%;
                                    }
                                }   
                                .name{
                                    color: $White;
                                    position: relative;
                                    z-index: 2;
                                    @include breakpoint($secreen-xs){
                                        line-height: 10px;
                                        font-size: 16px;
                                    }
                                }
                                .position{
                                    color: $White;
                                    position: relative;
                                    z-index: 2;
                                }
                        
                            }
                                .content2{
                                    &::after{
                                        transform: translate(-50%, -50%) rotate(134deg);
                                        top: 34px;
                                        @include breakpoint($secreen-xs){
                                            transform: none;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                        }
                                    }
                                }
                        }
                    }
                    .box-1{
                    &::after{
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(-46deg);
                        width: 157%;
                        height: 119%;
                        z-index: -1;
                        background: rgba(255, 255, 255, 0.1);
                        @include breakpoint($secreen-xs){
                            transform: none;
                            top: 0;
                            left: 0;
                            height: 100%;
                        }
                    }  
                    }
                }
            }
            .swiper-pagination{
                display: none;
                @include breakpoint($secreen-xs){
                    display: block;
                }
                .swiper-pagination-bullet{
                    background: $Gradient;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    .icon-otr{
        position: absolute;
        bottom: 27%;
        left: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 100%;
        z-index: 999;
        padding: 0 30px;
        @include breakpoint($secreen-xs){
            display: none;
        }
        .arrow-otr{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            width: 52px;
            height: 52px;
            left: 0;
            right: 0;
            &::before{
                content: "";
                position: absolute;
                background-color: #FFFFFF;
                opacity: 0.1;
                width: 199%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-50deg);
                z-index: -1;
                transition: .5s;
            }
            &:hover::before{
                opacity: 1;
                background: $Gradient;
            }
            &::after{
                display: none;
            }
            .icon{
                font-size: 24px;
                color: $White;
            }
        }
    }
}
      

/*------------------------------------------
            team-Section End Here
-------------------------------------------*/

/*------------------------------------------
            Contact Start  Here
-------------------------------------------*/

.contact{
    padding-top: 106px;
    margin-bottom: 130px;
    @include breakpoint($secreen-xs){
        padding-top: 80px;
        margin-bottom: 80px;
    }
    .container{
        .wrapper{
            text-align: center;
            .heading-LB{
                background-image: $Gradient;
                color: transparent;
                display: inline;
                -webkit-background-clip: text;
            }
            .heading-H2{
                color: $White;
                margin-top: 16px;
                margin-bottom: 72px;
                @include breakpoint($secreen-xs){
                    margin-bottom: 30px;
                }
            }
        }
        .row-custom{
            justify-content: space-between;
            .col-box{
                .col-innr{
                
                    .otr-input{
                        position: relative;
                        z-index: 99;
                        overflow: hidden;
                        &::after{
                            content: "";
                            position: absolute;
                            width: 73%;
                            background-color: #FFFFFF;
                            opacity: 0.1;
                            height: 1010%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                            z-index: -1;
                            @include breakpoint($secreen-xs){
                                transform: none;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                            }
                        }
                            
                        .input-innr{
                            color: $White-65;
                            padding: 11px 24px;
                            width: 100%;
                            background-color: transparent;
                            border: none;
                        }
                    }
                    .otr-input2{
                        margin-top: 24px;
                        position: relative;
                        overflow: hidden;
                        z-index: 99;
                        &::after{
                            content: "";
                            position: absolute;
                            width: 73%;
                            background-color: #FFFFFF;
                            opacity: 0.1;
                            height: 1010%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%) rotate(45deg);
                            z-index: 
                            -1;
                            @include breakpoint($secreen-xs){
                                transform: none;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                            }
                        }
                            
                        .input-innr{
                            padding: 11px 24px;
                            width: 100%;
                            color: $White-65;
                            background-color: transparent;
                            border: none;
                        }
                    }
                    .otr-textarea{
                        position: relative;
                        overflow: hidden;
                        margin-top: 24px;
                        z-index: 99;
                        &::after{
                            content: "";
                            width: 79%;
                            height: 837%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                            position: absolute;
                            background-color: #FFFFFF;
                            opacity: 0.1;
                            z-index: -1;
                            @include breakpoint($secreen-xs){
                                transform: none;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                            }
                        }
                        .textarea{
                            padding: 11px 24px;
                            height: 110px;
                            color: $White-65;
                            width: 100%;
                            background-color: transparent;
                            border: none;
                            resize: none;
                            
                        }
                    }
                    .action {
                        margin-top: 24px;
                        display: flex;
                        .btn-theme{
                            padding: 10px 24px;
                            display: flex;
                            color: $White;
                            background: transparent;
                            align-items: center;
                            position: relative;
                            overflow: hidden;
                            z-index: 1;
                                &::after{
                                    content: "";
                                    position: absolute;
                                    width: 107%;
                                    height: 317%;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%) rotate(-45deg);
                                    background: $Gradient;
                                    transition: 0.3s;
                                    z-index: -1;
                                    @include breakpoint($secreen-xs){
                                        transform: none;
                                        top: 0;
                                        left: 0;
                                        height: 100%;
                                        width: 100%;
                                    }
                                }
                            .icon-innr{
                                color: $White;
                                font-size: 24px;
                                margin-left: 10px;
                            }
                        }
                    }
                    
                        
                }
            }
            .col-box2{
                    .col-innr{
                        padding: 28px 36px 36px 36px;
                        position: relative;
                        overflow: hidden;
                        z-index: 99;
                        @include breakpoint($secreen-max-md){
                            margin-top: 56px;
                        }
                        &::after{
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        background-color: #FFFFFF;
                        opacity: 0.1;
                        width: 120%;
                        height: 200%;
                        z-index: -1;
                        @include breakpoint($secreen-xs){
                            transform: none;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                        }
                        }
                        .heading-H4{
                            color: $White;
                        }
                        .heading-SB{
                            color: $White-65;
                            margin-top: 16px;
                        }
                        .heading-MB{
                            background-image: $Gradient;
                            color: transparent;
                            display: inline;
                            -webkit-background-clip: text;
                        }
                        .ul-items{
                            margin-top: 32px;
                            display: flex;
                            padding-left: 0px;
                            padding-bottom: 0px;
                            .li-items{
                                overflow: hidden;
                                &:not(:last-child)
                                {
                                    margin-right: 16px;
                                }
                            
                                .a-items{
                                    color: $White;
                                    font-size: 24px;
                                    position: relative;
                                    overflow: hidden;
                                    width: 52px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 52px;
                                    &::after{
                                        content: "";
                                        position: absolute;
                                        background-color: #FFFFFF;
                                        opacity: 0.1;
                                        width: 199%;
                                        height: 100%;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%) rotate(42deg);
                                        z-index: -1;
                                        transition: .5s;
                                    }
                                    &:hover::after{
                                        opacity: 1;
                                        background: $Gradient;
                                    }
                                }
                                .item2{
                                &::after{
                                    transform: translate(-50%, -50%) rotate(-50deg);
                                }
                                }
                            }
                        }
                    }
            }
        }
    }
}

/*------------------------------------------
            Contact End  Here
-------------------------------------------*/

/*------------------------------------------
            FAQ Start  Here
-------------------------------------------*/

.FAQ{
    margin-top: 100px;
    position: relative;
    padding: 100px 0;
    background-color: $White-5;
    overflow: hidden;
    &::after{
        content: "";
        position: absolute;
        width: 500px;
        height: 500px;
        background: $Gradient;
        border-radius: 100%;
        filter: blur(500px);
        top: 52px;
        left: 7px;
        z-index: -1;
    }
    .container{
        .wrapper{ 
            text-align: center;
            padding-bottom: 72px;
            @include breakpoint($secreen-max-md){
                padding-bottom: 32px;
            }
            .heading-LB{
                background-image: $Gradient;
                display: inline;
                color: transparent;
                -webkit-background-clip: text;
            }
            .heading-H2{
                color: $White;
            }
        }
        .row-custom{
            justify-content: center;
            .accordion{
                margin-top: 24px;
                .accordion-item{
                    background-color: transparent;
                    margin-bottom: 24px;
                    .accordion-header{
                        .accordion-button{
                            background-color: transparent;
                            position: relative;
                            overflow: hidden;
                            color: $White-65;
                            &::before{
                                content: "";
                                position: absolute;
                                width: 100% !important;
                                height: 906% !important;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%) rotate(45deg);
                                background: $White-light !important;
                                z-index: -1;
                                @include breakpoint($secreen-max-md){
                                    transform: none;
                                    top: 0;
                                    left: 0;
                                    height: 100%;
                                }
                            }
                            &:hover::before{
                                background: $Gradient;
                            }
                            &:focus{
                                border: none;
                                box-shadow: none;
                            }
                            &::after {
                                flex-shrink: 0;
                                width: 1.25rem;
                                height: 1.25rem;
                                margin-left: auto;
                                content: "";
                                background-image: url('/assets/img/add-line.svg');
                                background-repeat: no-repeat;
                                background-size: 1.25rem;
                                transition: transform .2s ease-in-out;
                            }
                        }
                        .accordion-button1{
                            &::before{
                                transform: translate(-50% , -50%)rotate(-45deg);
                                @include breakpoint($secreen-max-md){
                                    transform: none;
                                    top: 0;
                                    left: 0;
                                    height: 100%;
                                }
                            }
                        }
                        .accordion-button:not(.collapsed){
                            &::after {
                                background-image: url('/assets/img/subtract-line.svg');
                            }
                            &::before{
                                background: $Gradient !important;
                            }
                            
                        }
                    }
                    .accordion-collapse{
                        position: relative;
                        overflow: hidden;
                        &::after{
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%) rotate(-45deg);
                            width: 100%;
                            height: 544%;
                            background: $White-light;
                        }
                        .accordion-body{
                            color: $White-65;
                        }
                    }
                    .accordion-collapse1{
                        &::after{
                            transform: translate(-50% , -50%)rotate(45deg);
                        }
                    }
                }
            }
            .accordion-last{
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .margin-0{
                @include breakpoint($secreen-max-md){
                    margin-top: 0;
                }
            }
        }
    }
}

/*------------------------------------------
            FAQ End  Here
-------------------------------------------*/

/*------------------------------------------
            Footer Start  Here
-------------------------------------------*/
     footer{
         background-color: $White-5;
         padding: 24px 0px;
         .container{
           .try{
               display: flex;
               justify-content: space-between;
               align-items: center;
               @include breakpoint($secreen-xs){
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }
              .heading-SB{
                  color: $White-65;
                  @include breakpoint($secreen-xs){
                    margin-top: 20px;
                }
                  .link{
                      background-image: $Gradient;
                      color: transparent;
                      display: inline;
                      -webkit-background-clip: text;
                  }
              }
           }
     }
}

.freepik-link-otr{
    margin: 24px 0;
    .container{
        .linkk-otr{
            text-align: center;
            .linkk-freepik{
                background-image: $Gradient;
                color: transparent;
                display: inline;
                -webkit-background-clip: text;
            }
        }
    }
}

/*------------------------------------------
            Footer End  Here
-------------------------------------------*/